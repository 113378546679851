import { observable, action, computed, IReactionDisposer, reaction, when, runInAction } from 'mobx'
import { poolsStore } from '@/stores/pools-store'
import moment from 'moment'
import { PoolStore } from '@/stores/pool-store'
import { asyncAction } from 'mobx-utils'
import { VMain } from 'vuetify/lib'
import { PoolModel } from '@/models/pool-model'
import { walletStore } from '@/stores/wallet-store'
import { register } from 'register-service-worker'
import { apiService } from '@/services/api-services'
import { snackController } from '@/components/snack-bar/snack-bar-controller'
import { ApplyModel } from '@/models/apply-model'
import { FixedNumber } from '@ethersproject/bignumber'
import { numberHelper } from '@/helper/number.hepler'
import { bigNumberHelper, bnHelper } from '@/helper/bignumber-helper'
import { tierHelper, Zero } from '@/constants'
import { map, max, without, sortBy } from 'lodash'
import { promiseHelper } from '@/helper/promise-helper'
import { BlockChainHandler, BuildLobalStates } from '@/blockchainHandler/'
import { checkOptInStatus } from '@/blockchainHandler/utils'
import { from, Subscription, throwError } from 'rxjs'
import { concatMap, delay, retryWhen, take } from 'rxjs/operators'
import { StakingHandler } from '@/blockchainHandler/stakingHandler'

export interface Project {
  status: string
  startTime?: number
  endTime?: number
}

export class IdoApplyViewModel {
  @observable loadingPool = false
  @observable poolStore: PoolStore | null = null
  @observable unicodeName = ''
  @observable hCaptchaToken = ''
  @observable registerCheckbox = false
  @observable isLoadingRegister = false
  @observable apply?: ApplyModel | null = null
  @observable fetchingApply = false
  @observable fetchingData = false
  @observable fetchTicket = false
  @observable registrationDialog = false
  @observable depositTicketInput = ''
  @observable depositAmountInput = ''
  @observable depositError = ''
  @observable depositLoading = false
  @observable optInLoading = false
  @observable depositedTikets: any = []
  @observable loadingApplyTicket = false
  @observable isShowWinningTickets = true
  @observable isShowNoneWinningTickets = true
  @observable isOpInt = false
  @observable localState = {} as BuildLobalStates
  @observable tradeTokenBalance = Zero
  @observable claimedAmount = Zero
  @observable investedAmount = Zero
  @observable isRefund = false
  @observable acceptedAmount = Zero
  @observable refundLoading = false
  @observable purchasedToken = Zero
  @observable remainedToken = Zero
  @observable maximumToken = Zero
  @observable tierAmount = Zero
  @observable tierIndex = 0
  @observable fetchingStaking = false
  @observable stakingHandler?: StakingHandler = undefined

  _disposers: IReactionDisposer[] = []
  constructor() {
    this.stakingHandler = new StakingHandler()
    this._disposers = [
      reaction(
        () => walletStore.jwt,
        (x) => {
          this.apply = null
          if (x) {
            this.resetCache()
            this.fetchData()
          }
        },
        { fireImmediately: true }
      ),
      reaction(
        () => walletStore.account,
        (x) => {
          if (x) {
            this.clearStakingInfo()
            this.fetchStakingInfo()
          }
        },
        { fireImmediately: true }
      ),
    ]
  }

  destroy() {
    this._disposers.forEach((d) => d())
  }

  @asyncAction *loadPool(param: any) {
    this.loadingPool = true
    const { unicodeName } = param
    try {
      this.unicodeName = unicodeName
      this.poolStore = yield poolsStore.findPool(unicodeName)
    } catch (e: any) {
      console.log('load pool error', e.message | e.msg)
    } finally {
      this.loadingPool = false
    }
  }

  @action resetCache() {
    this.depositError = ''
    this.depositAmountInput = ''
    this.depositTicketInput = ''
  }

  @action clearStakingInfo() {
    this.tierAmount = Zero
    this.tierIndex = 0
  }

  @asyncAction *getContractContrains() {
    const account = walletStore.account as any
    if (!this.contract || !this.pool?.appID) return
    try {
      this.isOpInt = yield checkOptInStatus(account, this.pool?.appID)
      yield this.fetchUserInfo()
    } catch (e) {
      console.log('getContractContrains error')
    }
  }

  @asyncAction *fetchUserInfo() {
    const account = walletStore.account
    if (this.contract && account) {
      this.tradeTokenBalance = yield this.contract.getUserTradeTokenAmount(account)
      const { claimedAmount, acceptedAmount, investedAmount, receivedRefundAmount, isRefund } =
        yield this.contract.fetchUserContrain(account)
      this.acceptedAmount = acceptedAmount
      this.isRefund = isRefund
      this.claimedAmount = claimedAmount
      this.investedAmount = investedAmount
    }
  }

  @asyncAction *fetchStakingInfo() {
    if (!this.stakingHandler || !walletStore.account) return

    try {
      this.fetchingStaking = true
      const { amount, tier } = yield this.stakingHandler.getUserInfo(walletStore.account)
      this.tierAmount = amount
      this.tierIndex = tier
    } catch (e) {
      console.log('fetchStakingInfo failed', e)
    } finally {
      this.fetchingStaking = false
    }
  }

  @asyncAction *fetchData() {
    yield when(() => !!this.pool)
    this.fetchingData = true
    yield this.fetchApply()
    yield this.fetchTickets()
    if (this.contract && this.pool?.appID) {
      yield this.getContractContrains()
    }
    this.fetchingData = false
  }

  @asyncAction *fetchApply(forceLoading = true) {
    try {
      if (forceLoading) this.fetchingApply = true
      const res: any = yield apiService.applies.find({
        pool: this.pool?.id,
        investor: walletStore?.investor?.id,
        walletAddress: walletStore.account,
      }) || []
      if (res.length > 0) this.apply = res[0]
    } catch (e) {
      console.log('fetchApply is fail')
    } finally {
      if (forceLoading) this.fetchingApply = false
    }
  }
  @asyncAction *fetchTickets() {
    try {
      const res = yield apiService.tickets.find({
        pool: this.pool?.id,
        investor: walletStore?.investor?.id,
      }) || []
      this.depositedTikets = sortBy(map(res, 'ticketNo') || [])
    } catch (e) {
      console.log('fetchTicket is fail')
    }
  }

  @action registerCheckboxOnChange(value) {
    this.registerCheckbox = value
  }

  @action resetHCaptchaToken() {
    this.hCaptchaToken = ''
  }
  @action registrationDialogOnChange(value) {
    this.registrationDialog = value
  }
  @asyncAction *createApply(value) {
    this.isLoadingRegister = true
    this.hCaptchaToken = value
    try {
      this.apply = yield apiService.applies.createApply({
        walletAddress: walletStore.account,
        poolId: this.pool?.id,
        investorId: walletStore.investor?.id,
        token: this.hCaptchaToken,
        sitekey: this.sitekey,
      })
      snackController.success('Register succesfully')
      this.registrationDialog = false
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.isLoadingRegister = false
      this.resetHCaptchaToken()
    }
  }

  @action.bound changeShowWinningTickets() {
    this.isShowWinningTickets = !this.isShowWinningTickets
  }
  @action.bound changeShowNoneWinningTickets() {
    this.isShowNoneWinningTickets = !this.isShowNoneWinningTickets
  }

  @action.bound depositTicketInputOnChange(val) {
    this.depositError = ''
    if (val && numberHelper.isFloatNumber(val)) {
      this.depositTicketInput = val
      this.depositAmountInput = FixedNumber.from(this.depositTicketInput + '')
        .mulUnsafe(this.pricePerTicket)
        .toString()
      this.depositAmountInput = (+this.depositAmountInput).toString()
    } else {
      this.depositAmountInput = ''
    }
  }
  @action.bound depositAmountInputOnChange(val) {
    if (val && numberHelper.isFloatNumber(val)) {
      this.depositAmountInput = val
      this.depositTicketInput = FixedNumber.from(this.depositAmountInput + '')
        .divUnsafe(this.pricePerTicket)
        .toString()
      this.depositTicketInput = (+this.depositTicketInput).toString()
    } else {
      this.depositTicketInput = ''
    }
  }

  @computed get isValidDepositInput() {
    if (
      !this.depositTicketInput ||
      !this.depositAmountInput ||
      !numberHelper.isPositiveInterger(this.depositTicketInput) ||
      !numberHelper.isPositiveInterger(this.depositAmountInput) ||
      bnHelper.lte(FixedNumber.from(this.depositTicketInput), Zero) ||
      bnHelper.lte(FixedNumber.from(this.depositAmountInput), Zero)
    )
      return false
    return true
  }
  @computed get isVisbleLoading() {
    return walletStore.isLogin && (this.loadingPool || this.fetchingData)
  }

  @computed get depositedTicketNumber() {
    return this.investedAmount.divUnsafe(this.pricePerTicket).floor().toUnsafeFloat()
  }

  @computed get socialTicketNumber() {
    return this.apply?.socialTicketNumber || 0
  }

  @computed get stakingTicketNumber() {
    return this.apply?.stakingTicketNumber || 0
  }

  @computed get maxTickets() {
    return this.socialTicketNumber + this.stakingTicketNumber + this.goldenTicketNumber
  }

  @computed get remainingTickets() {
    const remainingTickets = this.maxTickets - this.depositedTicketNumber || 0
    return remainingTickets < 0 ? 0 : remainingTickets
  }
  @computed get isNoneWinningTicket() {
    return (
      (!this.isApplied || (this.socialTicketNumber + this.stakingTicketNumber <= 0 && this.isDepositStarted)) &&
      !this.isClaimStart &&
      this.isWhitelistEnded
    )
  }
  @computed get noneWinningTickets() {
    return without(this.depositedTikets, ...this.winningTickets) || []
  }

  @computed get hasWinningTickets() {
    return this.winningTickets.length > 0
  }
  @computed get hasRefundTickets() {
    return this.noneWinningTickets.length > 0
  }

  @computed get kycStateText() {
    const status = walletStore.investor?.status
    if (status === 'kyc-pending') return 'Pending'
    else if (status === 'kyc-verified') return 'Verified'
    else if (status === 'kyc-banned') return 'Banned'
    else return 'Unverified'
  }

  @computed get canClaimToken() {
    return this.isClaimStart && this.depositedTicketNumber > 0
  }

  @computed get isVisibleDeposit() {
    return this.isDepositStarted && !this.isDepositEnded
  }

  @computed get isVisibleTicketWinning() {
    return this.isLotteryPublic && !this.isClaimStart
  }
  @computed get isVisibleClaimToken() {
    return this.isClaimStart
  }

  @computed get isWaitingPublicWinningLottery() {
    return this.isDepositEnded && !this.isLotteryPublic
  }
  @computed get isVisibleTicketCollection() {
    return this.isWhitelistStarted && !this.isWhitelistEnded
  }
  @computed get isWaitingDeposit() {
    return this.isWhitelistEnded && !this.isDepositStarted
  }

  @computed get isApplied() {
    return !!this.apply
  }
  @computed get winningTickets() {
    return sortBy(this.apply?.data?.winningTickets || [])
  }

  @action connectWallet() {
    walletStore.showConnectWalletDialog(true)
  }

  @computed get pool(): PoolModel | undefined {
    return this.poolStore?.pool
  }

  @computed get isShowWhitelistFlow() {
    return (
      this.isWhitelistStarted &&
      !this.canRegisterWhitelist &&
      !this.isEnableProcessKyc &&
      walletStore.isLogin &&
      (!!this.apply || this.isWhitelistEnded)
    )
  }

  @computed get canRegisterWhitelist() {
    return (
      walletStore.isLogin &&
      !this.isApplied &&
      !this.fetchingData &&
      !this.loadingPool &&
      !this.isWhitelistEnded &&
      walletStore.isKycVerified
    )
  }

  @computed get refundAmount() {
    const refundAmount = this.investedAmount.subUnsafe(this.acceptedAmount)
    return bnHelper.gt(refundAmount, Zero) ? refundAmount : Zero
  }
  @computed get canRefund() {
    return !this.isRefund && bnHelper.gt(this.refundAmount, Zero)
  }

  @computed get isEnableProcessKyc() {
    return (
      !this.isApplied && !this.fetchingData && !this.loadingPool && !this.isWhitelistEnded && !walletStore.isKycVerified
    )
  }
  @computed get whitelistConfig() {
    return this.pool?.data?.whitelistConfig
  }
  @computed get pricePerTicket() {
    const price = this.pool?.data?.pricePerTicket || '100'
    return FixedNumber.from(price)
  }
  @computed get totalRaise() {
    return this.pool?.totalRaise
  }
  @computed get ratio() {
    return this.pool?.ratio
  }
  @computed get amount() {
    return this.pool?.amount
  }
  @computed get poolName() {
    return this.pool?.name
  }

  @computed get usdTicketAllocation() {
    return this.pricePerTicket.mulUnsafe(FixedNumber.from(`${this.maxTickets}`)) || Zero
  }

  @computed get sitekey() {
    return process.env.VUE_APP_HCAPTCHA_SITE_KEY
  }
  @computed get tradeToken() {
    return this.pool?.tradeToken
  }
  @computed get tradeTokenID() {
    return this.pool?.tradeTokenID
  }
  @computed get tokenID() {
    return this.pool?.tokenID
  }

  @computed get tokenName() {
    return this.pool?.tokenName
  }
  @computed get whitelistStart() {
    return this.whitelistConfig?.whitelistStart
  }
  @computed get whitelistEnd() {
    return this.whitelistConfig?.whitelistEnd
  }
  @computed get whitelistPublic() {
    return this.whitelistConfig?.whitelistPublic
  }
  @computed get depositStart() {
    return this.whitelistConfig?.depositStart
  }
  @computed get depositEnd() {
    return this.whitelistConfig?.depositEnd
  }
  @computed get lotteryPublic() {
    return this.whitelistConfig?.lotteryPublic
  }
  @computed get claimStart() {
    return this.whitelistConfig?.claimStart
  }

  @computed get isWhitelistStarted() {
    return this.poolStore?.poolState?.isWhitelistStarted
  }
  @computed get contract() {
    return this.poolStore?.contract
  }

  @computed get isWhitelistEnded() {
    return this.poolStore?.poolState?.isWhitelistEnded
  }
  @computed get isClaimStart() {
    return this.poolStore?.poolState?.isClaimStart
  }
  @computed get isDepositStarted() {
    return this.poolStore?.poolState?.isDepositStarted
  }
  @computed get isDepositEnded() {
    return this.poolStore?.poolState?.isDepositEnded
  }
  @computed get isLotteryPublic() {
    return this.poolStore?.poolState?.isLotteryPublic
  }
  @computed get currentTier() {
    return tierHelper.findTierByTierIndex(this.tierIndex)
  }
  @computed get tierTicketNumber() {
    return this?.currentTier?.ticketNumber || 0
  }
  @computed get tierGoldenTicketNumber() {
    return this?.currentTier?.goldenTickeNumber || 0
  }
  @computed get currentgoldenTickeNumber() {
    return this.currentTier?.goldenTickeNumber || 0
  }

  @computed get canApplyStakingTickets() {
    return this.tierTicketNumber > this.stakingTicketNumber || this.tierGoldenTicketNumber > this.goldenTicketNumber
  }

  @action checkError() {
    if (!this.isValidDepositInput) {
      this.depositError = 'Invalid input'
      return
    }
    if (this.remainingTickets < parseInt(this.depositTicketInput)) {
      this.depositError = `The number of tickets cannot exceed ${this.remainingTickets}`
      return
    }
    if (bnHelper.gt(FixedNumber.from(this.depositAmountInput), this.tradeTokenBalance))
      this.depositError = 'Balance is not Sufficient'
    return
  }

  @asyncAction *applyStakingTickets() {
    if (!this.canApplyStakingTickets) return
    this.loadingApplyTicket = true
    try {
      promiseHelper.delay(1000)
      yield apiService.applies.applyStakingTickets({
        applyId: this.apply?.id,
        goldenTicketNumber: this.currentTier?.goldenTickeNumber,
        stakingTicketNumber: this.currentTier?.ticketNumber,
      })
      snackController.success('Apply tickets successfully')
      yield this.fetchApply(false)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.loadingApplyTicket = false
    }
  }
  @asyncAction *depositTicket() {
    this.checkError()
    if (this.depositError) return
    const contract = this.poolStore?.contract
    if (!contract) return
    try {
      this.depositLoading = true
      const ticketAmountInput = +this.depositTicketInput
      yield contract.buy(walletStore.account, FixedNumber.from(`${this.depositAmountInput}`))
      const depsitedAmount = this.depositedTicketNumber + ticketAmountInput
      const payload = {
        investorId: walletStore.investor?.id,
        poolId: this.pool?.id,
        amount: ticketAmountInput,
        depositedTicketNumber: depsitedAmount,
        applyId: this.apply?.id,
      }
      yield apiService.applies.depositTicket(payload)
      snackController.success('Deposit tickets successfully')
      this.resetCache()
      yield this.fetchUserInfo()
      yield this.fetchApply(false)
    } catch (e) {
      snackController.commonError(e)
    } finally {
      this.depositLoading = false
    }
  }

  @asyncAction *opInt() {
    const contract = this.poolStore?.contract
    if (contract && this.pool?.appID) {
      try {
        this.optInLoading = true
        yield contract.sendOptIn(walletStore.account)
        this.isOpInt = yield checkOptInStatus(walletStore.account, this.pool?.appID)
        yield this.fetchUserInfo()
        snackController.success('Opt-in successfully')
      } catch (e) {
        snackController.commonError(e)
      } finally {
        this.optInLoading = false
      }
    }
  }
  @asyncAction *refund() {
    if (this.contract) {
      try {
        this.refundLoading = true
        yield this.contract.refund(walletStore.account)
        snackController.success(`Refund ${this.refundAmount.toString()} ${this.pool?.tradeToken} successfully`)
        yield this.fetchUserInfo()
      } catch (e) {
        snackController.commonError(e)
      } finally {
        this.refundLoading = false
      }
    }
  }

  @computed get totalTicketsForceShow() {
    return this.tierTicketNumber > this.stakingTicketNumber || this.tierGoldenTicketNumber > this.goldenTicketNumber
      ? this.tierTicketNumber
      : this.stakingTicketNumber
  }

  @computed get winningTicketNotGolden() {
    return this.winningTickets.length > this.goldenTicketNumber
      ? this.winningTickets.length - this.goldenTicketNumber
      : 0
  }
  @computed get depositedGoldenTicket() {
    return this.winningTickets.length > this.goldenTicketNumber ? this.goldenTicketNumber : this.winningTickets.length
  }

  @computed get goldenTicketForceShow() {
    return this.tierTicketNumber > this.stakingTicketNumber || this.tierGoldenTicketNumber > this.goldenTicketNumber
      ? this.tierGoldenTicketNumber
      : this.goldenTicketNumber
  }

  @computed get currentStep() {
    if (!this.whitelistStart || !this.isWhitelistStarted) return 0
    else if (!this.isDepositStarted) return 1
    else if (!this.isLotteryPublic) return 2
    else if (!this.isClaimStart) return 3
    else return 4
  }

  @computed get countdownText() {
    return this.poolStore?.poolState?.countdownText || ''
  }
  @computed get countdownSeconds() {
    return this.poolStore?.poolState?.countdownSeconds || 0
  }
  @computed get countdownMinutes() {
    return this.poolStore?.poolState?.countdownMinutes || 0
  }
  @computed get countdownHours() {
    return this.poolStore?.poolState?.countdownHours || 0
  }
  @computed get countdownDays() {
    return this.poolStore?.poolState?.countdownDays || 0
  }
  @computed get goldenTicketNumber() {
    return this.apply?.goldenTicketNumber || 0
  }

  @computed get timelineSteps() {
    return (
      [
        {
          title: 'Preparation',
          subTitle: 'We are preparing launch of this project.',
        },
        {
          title: 'Whitelist',
          subTitle: 'You can now whitelist yourself for the lottery.',
        },
        {
          title: 'Deposit',
          subTitle: 'You have to deposit to get tickets.',
          // stepTime: this.currentStep <= 2 ? this.whitelistPublic : this.depositStart,
          stepTime: this.depositStart,
        },
        {
          title: 'Lottery winner',
          subTitle: 'See if you have any winning lottery tickets.',
          // stepTime: this.currentStep <= 3 ? this.depositEnd : this.lotteryPublic,
          stepTime: this.lotteryPublic,
        },
        {
          title: 'Claim',
          subTitle: 'Winners can claim tokens.',
          // stepTime: this.currentStep <= 4 ? this.claimStart : '',
          stepTime: this.claimStart,
        },
      ] || []
    )
  }
}
