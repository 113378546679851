var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('registration-dialog',{ref:"registrationDialog",attrs:{"vm":_vm.vm}}),_c('v-container',{staticClass:"mt-4"},[_c('div',[(_vm.$vuetify.breakpoint.lgAndUp)?_c('div',{staticClass:"mb-6"},[_c('div',{staticClass:"d-flex fill-width justify-space-between"},[_c('div',{staticClass:"d-flex flex-column whitelist-info-container-desktop"},[_c('pool-info-desktop'),_c('v-spacer',{staticClass:"flex-fill"}),(!_vm.vm.isClaimStart)?_c('count-down',{class:_vm.$vuetify.breakpoint.lgAndDown ? 'mt-5' : 'mt-4',attrs:{"vm":_vm.vm}}):_c('div',{staticClass:"pa-5 primary mt-6 d-flex align-center border-radius-10"},[_c('v-img',{attrs:{"src":require("@/assets/icons/finnal-flag-icon.svg"),"max-width":"93","contain":""}}),_c('div',{staticClass:"finnal-announcement ml-4"},[_vm._v(_vm._s(_vm.vm.poolName)+" public sale has finished!")])],1)],1),_c('div',{staticClass:"timeline-container-desktop",staticStyle:{"min-height":"100%"}},[_c('timeline',{attrs:{"vm":_vm.vm}})],1)]),(!_vm.vm.isVisbleLoading)?_c('div',{staticClass:"d-flex fill-width justify-space-between"},[_c('div',{staticClass:"whitelist-info-container-desktop"}),_c('div',{staticClass:"timeline-container-desktop"},[_c('button-apply',{class:!_vm.vm.isShowWhitelistFlow ? 'my-3' : ''})],1)]):_c('div',{staticClass:"fill-width d-flex align-center justify-center"},[_c('v-progress-circular',{staticClass:"my-8",attrs:{"size":50,"color":"primary","indeterminate":""}})],1)]):_c('v-row',{staticClass:"ma-0"},[_c('v-col',{staticClass:"pa-0",class:{
            'pa-0': _vm.$vuetify.breakpoint.smAndDown,
            'pa-0 pr-3': !_vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"cols":"12","sm":"6"}},[_c('pool-info-mobile')],1),_c('v-col',{class:{
            'pl-3 pa-0': !_vm.$vuetify.breakpoint.smAndDown,
            'pa-0 mt-6': _vm.$vuetify.breakpoint.smAndDown,
          },attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"fill-width fill-height",staticStyle:{"position":"relative"}},[(_vm.$vuetify.breakpoint.xs && !_vm.vm.isShowWhitelistFlow)?_c('div',{staticClass:"algo-grey darken-2 py-4 fill-width",staticStyle:{"position":"absolute","top":"38%","z-index":"2"}},[_c('button-apply')],1):_vm._e(),_c('timeline',{staticClass:"flex-fill",attrs:{"vm":_vm.vm}})],1)]),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[(!_vm.vm.isClaimStart)?_c('count-down',{class:{
              'mt-6': !_vm.$vuetify.breakpoint.mdAndUp,
              'mt-5': _vm.$vuetify.breakpoint.mdAndUp,
            },attrs:{"vm":_vm.vm}}):_c('div',{staticClass:"pa-7 primary mt-6 d-flex flex-column justify-center align-center border-radius-10"},[_c('v-img',{attrs:{"src":require("@/assets/icons/finnal-flag-icon.svg"),"max-width":"93","contain":""}}),_c('div',{staticClass:"finnal-announcement-xs mt-5"},[_vm._v(_vm._s(_vm.vm.poolName)+" public sale has finished!")])],1)],1),(!_vm.$vuetify.breakpoint.smOnly)?_c('v-col',{staticClass:"pa-0 mt-6",attrs:{"cols":"12"}},[_c('button-apply')],1):_vm._e()],1)],1),(_vm.vm.isShowWhitelistFlow && !_vm.vm.isVisbleLoading)?_c('div',[(_vm.vm.isNoneWinningTicket)?_c('ticket-none-winner'):(_vm.vm.isVisibleTicketCollection)?_c('ticket-collection'):(_vm.vm.isWaitingDeposit)?_c('waiting-deposit-ticket'):(_vm.vm.isVisibleDeposit)?_c('ticket-deposit'):(_vm.vm.isWaitingPublicWinningLottery)?_c('waiting-winning-lottery'):(_vm.vm.isVisibleTicketWinning)?_c('ticket-winning'):_vm._e()],1):_vm._e()]),(_vm.$vuetify.breakpoint.smOnly)?_c('div',{staticClass:"position-fixed z-1000 algo-grey darken-2 pa-5 fill-width",staticStyle:{"bottom":"0"}},[_c('button-apply')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }