import { render, staticRenderFns } from "./ido-apply.vue?vue&type=template&id=f0851f7e&scoped=true&"
import script from "./ido-apply.vue?vue&type=script&lang=ts&"
export * from "./ido-apply.vue?vue&type=script&lang=ts&"
import style0 from "./ido-apply.vue?vue&type=style&index=0&id=f0851f7e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0851f7e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VContainer,VImg,VProgressCircular,VRow,VSpacer})
