




































































































import { Component, Provide, Ref, Vue, Watch } from 'vue-property-decorator'
import { IdoApplyViewModel } from '../viewmodel/ido-apply-viewmodel'
import { Observer } from 'mobx-vue'
import { walletStore } from '@/stores/wallet-store'

@Observer
@Component({
  components: {
    'count-down': () => import('../components/count-down.vue'),
    'button-apply': () => import('../components/button-apply.vue'),
    'pool-info-mobile': () => import('../components/pool-info-mobile.vue'),
    'pool-info-desktop': () => import('../components/pool-info-desktop.vue'),
    timeline: () => import('../components/timeline.vue'),
    'ticket-collection': () => import('../components/ticket-collection.vue'),
    'ticket-deposit': () => import('../components/ticket-deposit.vue'),
    'waiting-public-ticket': () => import('../components/waiting-public-ticket.vue'),
    'waiting-deposit-ticket': () => import('../components/waiting-deposit-ticket.vue'),
    'waiting-winning-lottery': () => import('../components/waiting-winning-lottery.vue'),
    'ticket-winning': () => import('../components/ticket-winning.vue'),
    'ticket-none-winner': () => import('../components/ticket-none-winner.vue'),
    'registration-dialog': () => import('../components/registration-dialog.vue'),
  },
})
export default class IdoApply extends Vue {
  @Ref('registrationDialog') registrationDialog: any
  walletStore = walletStore

  @Provide() vm = new IdoApplyViewModel()

  @Watch('$route.params.unicodeName', { immediate: true }) async onPoolIdChanged(val: string) {
    if (val) {
      await this.vm.loadPool({
        unicodeName: val,
      })
    }
  }

  destroyed() {
    this.vm.destroy()
  }
}
